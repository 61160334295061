﻿import 'dotenv/config'

// @ts-ignore
import express from 'express'
// @ts-ignore
import { autocomplete } from '@algolia/autocomplete-js';
// @ts-ignore
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
// @ts-ignore
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
// @ts-ignore
import algoliasearch from 'algoliasearch/lite';
// @ts-ignore
import historyRouter from 'instantsearch.js/es/lib/routers/history';

const appId = process.env.APP_ID;
const apiKey = process.env.API_KEY;
const indexName = process.env.INDEX_NAME;
const searchClient = algoliasearch(appId, apiKey);
const baseUrl = '/Search';
const instantSearchRouter = historyRouter();

const querySuggestionsPlugin = createQuerySuggestionsPlugin({
	searchClient,
	indexName: `${indexName}_query_suggestions`,
	getSearchParams() {
		return {
			hitsPerPage: 10,
		};
	},
	categoryAttribute: [
		indexName,
		'facets',
		'exact_matches',
		'Categories.lvl1',
	],
	itemsWithCategories: 2,
	categoriesPerItem: 2,
	transformSource({ source }) {
		return {
			...source,
			onSelect({ item }) {
				let url = '';
				if (item.__autocomplete_qsCategory) {
					let category = item.__autocomplete_qsCategory;

					url = `${baseUrl}/${category.replaceAll(' >> ', '/').replaceAll('-', '_').replaceAll(', ', '--').replaceAll(' & ', '---').replaceAll(' ', '-')}?keywords=${encodeURIComponent(item.query)}`;
				} else {
					url = `${baseUrl}?keywords=${encodeURIComponent(item.query)}`;
				}
				window.location.href = url;
			},
		};
	},
});

const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
	key: 'RECENT_SEARCH',
	limit: 5,
	transformSource({ source }) {
		return {
			...source,
			onSelect({ item }) {
				let url = '';
				if (item.category) {
					let category = item.category;

					url = `${baseUrl}/${category.replaceAll(' >> ', '/').replaceAll('-', '_').replaceAll(', ', '--').replaceAll(' & ', '---').replaceAll(' ', '-')}?keywords=${encodeURIComponent(item.label)}`;
				} else {
					url = `${baseUrl}?keywords=${encodeURIComponent(item.label)}`;
				}
				window.location.href = url;
			},
		};
	},
});

const query = new URLSearchParams(document.location.search).get('keywords');

autocomplete({
	container: '#algolia-autocomplete',
	placeholder: 'Enter keyword, item number or mfg. number',
	openOnFocus: true,
	plugins: [recentSearchesPlugin, querySuggestionsPlugin],
	detachedMediaQuery: 'none',
	initialState: {
		query: query !== null ? decodeURIComponent(query) || '' : '',
	},
	onSubmit({ state }) {
		let url = `${baseUrl}?keywords=${encodeURIComponent(state.query)}`;
		window.location.href = url;
	}
});

autocomplete({
	container: '#algolia-autocomplete-sm',
	placeholder: 'Enter keyword, item number or mfg. number',
	openOnFocus: true,
	plugins: [recentSearchesPlugin, querySuggestionsPlugin],
	detachedMediaQuery: 'none',
	initialState: {
		query: query !== null ? decodeURIComponent(query) || '' : '',
	},
	onSubmit({ state }) {
		let url = `${baseUrl}?keywords=${encodeURIComponent(state.query)}`;
		window.location.href = url;
	}
});